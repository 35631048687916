import {
  IDeserializedPaginatedQuery,
  ILoadPaginatedForOrg,
  SmartrrFilterType,
} from "@smartrr/shared/utils/paginatedQuery";
import { debounce } from "lodash";
import { useMemo } from "react";

import { DEFAULT_INPUT_DEBOUNCE_TIME } from "@vendor-app/constants/table";

import { DebouncedUpdate } from "../../types";

export const getQueryParamsForFilters = (
  queryParams: any,
  filter?: SmartrrFilterType,
  type?: "filterLike" | "filterIn"
): IDeserializedPaginatedQuery => {
  const newQueryParams = queryParams;

  if (filter && !!Object.keys(filter).length) {
    const filterKeys = Object.keys(filter);
    for (let i = 0; i < filterKeys.length; i++) {
      const field = filterKeys[i];
      const value = filter[field];

      if (type) {
        switch (type) {
          case "filterIn": {
            newQueryParams[type] = {
              ...newQueryParams[type],
              [field]: Array.isArray(value) ? value : [value],
            };
            break;
          }
          case "filterLike": {
            newQueryParams[type] = {
              ...newQueryParams[type],
              [field]: Array.isArray(value) ? value.join(";") : value,
            };
            break;
          }
        }
      }

      if (value && !type) {
        switch (field) {
          case "items": {
            newQueryParams.filterIn = {
              ...newQueryParams.filterIn,
              variantId: Array.isArray(value) ? value : [value],
            };
            break;
          }

          case "createdDate":
          case "date":
          case "orderProcessedDate":
          case "billingDate":
          case "updatedDate":
          case "upcomingOrderDate":
          case "sellingPlanId":
          case "emailOrName":
          case "customerEmail":
          case "shopifyId":
          case "status": {
            newQueryParams.filterIn = {
              ...newQueryParams.filterIn,
              [field]: Array.isArray(value) ? value : [value],
            };
            break;
          }
          default: {
            newQueryParams.filterLike = {
              ...newQueryParams.filterLike,
              [field]: Array.isArray(value) ? value : [value],
            };
          }
        }
      }
    }
  }

  return newQueryParams;
};

export const useFilterUpdate = (
  loadData: (arg: ILoadPaginatedForOrg) => any,
  setPageNumber: (newPage: number) => void,
  debounceDelayTime: number = DEFAULT_INPUT_DEBOUNCE_TIME
) => {
  const debouncedUpdate = useMemo(() => {
    return debounce<DebouncedUpdate>(
      (pageNumber, pageSize, orderByField, orderByValue, triggeredBySearch, filter, ...rest) => {
        if (triggeredBySearch && pageNumber !== 1) {
          // open first page. State change will trigger useEffect hook which will dispatch action below
          setPageNumber(1);
          return;
        }

        const queryParams: IDeserializedPaginatedQuery = {
          pageNumber: pageNumber - 1,
          pageSize,
          orderBy: {
            [orderByField]: orderByValue,
          },
        };

        const newQueryParams = getQueryParamsForFilters(queryParams, filter);
        return loadData({ ...rest, queryParams: newQueryParams });
      },
      debounceDelayTime
    );
  }, []);

  return [debouncedUpdate] as [typeof debouncedUpdate];
};
