import { IS_BROWSER } from "@smartrr/shared/constants";
import { SmartrrFilterType, SmartrrSortType } from "@smartrr/shared/utils/paginatedQuery";
/* Constants */
export const DEFAULT_PAGE = 1;
export const DEFAULT_SIZE = 30;
export const DEFAULT_TABLE_HEIGHT = IS_BROWSER ? window.innerHeight - 195 : 600;
export const DEFAULT_DEBOUNCE_TIME = 100;
export const SHORT_DEBOUNCE_TIME = 50;
export const DEFAULT_INPUT_DEBOUNCE_TIME = 300;
export const DEFAULT_MAX_DESKTOP_BUTTONS = 5;
export const DEFAULT_MAX_MOBILE_BUTTONS = 3;

/* Styles */
export const tableBorderStyle: React.CSSProperties = {
  border: "1px solid #ddd",
  borderRadius: 5,
};

export const tableCellStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const tableBoldCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  fontWeight: "bold",
};

export const productImageCellStyle: React.CSSProperties = {
  ...tableCellStyle,
  paddingTop: 8,
  paddingBottom: 8,
};

export interface TableSettingProps {
  pageNumber: number;
  pageSize: number;
  orderByField: string;
  orderByValue: SmartrrSortType;
  filter: SmartrrFilterType;
}

export interface TableHandlersProps {
  setPageSize: (pageSize: number) => void;
  setPageNumber: (pageNumber: number) => void;
  setOrderByValue: (orderValue: SmartrrSortType) => void;
  setOrderByField: (fieldName: string) => void;
  setFilter: (filter: SmartrrFilterType) => void;
}

export interface TableProps extends TableSettingProps, TableHandlersProps {}
